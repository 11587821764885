// GameSetup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './GameSetup.css';
import comicLogo from './images/comiclogo12.png';
import bubble from './images/storykillerbubble.png';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const API_URL = process.env.REACT_APP_API_URL || '';

function GameSetup() {
  const [showForm, setShowForm] = useState(false);
  const [playerCount, setPlayerCount] = useState(4);
  const [playerNames, setPlayerNames] = useState(['', '', '', '']);
  const [gameCodes, setGameCodes] = useState(null);
  const [error, setError] = useState(null);
  const [gameCreated, setGameCreated] = useState(false);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const navigate = useNavigate();

  // New state variables for game settings
  const [wordCount, setWordCount] = useState(5);
  const [genre, setGenre] = useState('Default');

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const genres = [
    'Default',
    'Soap Opera',
    'Friends TV Show',
    'Horror',
    'Love Story',
    'Shakespearean',
    'Kafka',
    'Hemingway',
    'US TV Show The Office',
    'Christmas'
  ];

  const handleStartClick = () => {
    setShowForm(true);
  };

  const handleHowToPlayClick = () => {
    navigate('/how-to-play');
  };

  const handleGameSettingsClick = () => {
    navigate('/game-settings');
  };

  const handlePlayerCountChange = (e) => {
    const count = parseInt(e.target.value);
    setPlayerCount(count);
    setPlayerNames(Array(count).fill(''));
  };

  const handlePlayerNameChange = (index, name) => {
    const newNames = [...playerNames];
    newNames[index] = name;
    setPlayerNames(newNames);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/api/games`, {
        playerNames,
        wordCount,
        genre,
      });
      setGameCodes({
        gameId: response.data.gameId,
        players: response.data.players,
      });
      setGameCreated(true);
    } catch (error) {
      console.error('Error creating game:', error);
      setError('Failed to create game. Please try again.');
    }
  };

  const copyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedIndex(index);
        setTimeout(() => setCopiedIndex(null), 2000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  const toggleAdvancedOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  return (
    <div className="game-setup-container">
      <div className="game-setup-content">
        {!showForm ? (
          <>
            <img
              src={bubble}
              alt="storyKiller speech bubble"
              className="bubble-image"
            />
            <div className="button-container">
              <button className="start-button" onClick={handleStartClick}>
                New Game
              </button>
              <button className="secondary-button" onClick={handleHowToPlayClick}>
                How to Play
              </button>
{/*               <button className="secondary-button" onClick={handleGameSettingsClick}>
                Game Settings
              </button> */}
            </div>
            <img
              src={comicLogo}
              alt="storyKiller gun fight"
              className="comic-logo-image"
            />
          </>
        ) : (
          <div className="game-setup-form">
            <h1>StoryKiller Game Setup</h1>
            <form onSubmit={handleSubmit}>
              <label>
                Number of Players:
                <input
                  type="number"
                  min="4"
                  max="10"
                  value={playerCount}
                  onChange={handlePlayerCountChange}
                />
              </label>
              {playerNames.map((name, index) => (
                <div key={index}>
                  <label>
                    Player {index + 1} Name:
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => handlePlayerNameChange(index, e.target.value)}
                      required
                    />
                  </label>
                </div>
              ))}
              <button type="button" onClick={toggleAdvancedOptions}>
                {showAdvancedOptions ? 'Hide Advanced Options' : 'Show Advanced Options'}
              </button>
              {showAdvancedOptions && (
                <div className="advanced-options">
                  <div>
                    <label htmlFor="wordCount">Words per phrase (2-10): </label>
                    <input
                      type="number"
                      id="wordCount"
                      value={wordCount}
                      onChange={(e) =>
                        setWordCount(
                          Math.max(2, Math.min(10, parseInt(e.target.value) || 5))
                        )
                      }
                      min="2"
                      max="10"
                    />
                  </div>
                  <div>
                    <label htmlFor="genre">Story Genre: </label>
                    <select
                      id="genre"
                      value={genre}
                      onChange={(e) => setGenre(e.target.value)}
                    >
                      {genres.map((g) => (
                        <option key={g} value={g}>
                          {g}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {error && <p className="error-message">Error: {error}</p>}
              {!gameCreated ? (
                <button type="submit">Create Game</button>
              ) : (
                <div className="down-arrow">▼</div>
              )}
            </form>
          </div>
        )}
        {gameCodes && (
          <div className="game-created">
            <h2>Game Created!</h2>
            <p>Game ID: {gameCodes.gameId}</p>
            <h3>Player Codes:</h3>
            <ul>
              {gameCodes.players.map((player, index) => (
                <li key={index}>
                  {player.name}: <strong>{player.code}</strong>
                  <br />
                  Login URL:{' '}
                  <a
                    href={`/game/${gameCodes.gameId}/player/${player.code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${window.location.origin}/game/${gameCodes.gameId}/player/${player.code}`}
                  </a>
                  <button
                    className="copy-button"
                    onClick={() =>
                      copyToClipboard(
                        `${window.location.origin}/game/${gameCodes.gameId}/player/${player.code}`,
                        index
                      )
                    }
                  >
                    <ContentCopyIcon fontSize="small" />
                    {copiedIndex === index ? 'Copied!' : 'Copy'}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default GameSetup;
