import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HowToPlay.css';

function HowToPlay() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="how-to-play-container">
      <h1>How to Play StoryKiller</h1>
      <p>
        Welcome to **StoryKiller**, a game of deception and storytelling!
      </p>
      <h2>Objective</h2>
      <p>
        Survivors: Identify and vote out the StoryKiller before they eliminate all survivors.
      </p>
      <p>
        StoryKiller: Eliminate survivors without getting caught.
      </p>
      <h2>Gameplay</h2>
      <ol>
        <li>
          Each player receives a phrase. The StoryKiller receives a different phrase.
        </li>
        <li>
          Players take turns telling a story that incorporates their phrase.
        </li>
        <li>
          After all stories are told, a player ends the round.
        </li>
        <li>
          The StoryKiller secretly chooses a player to eliminate.
        </li>
        <li>
          Surviving players vote on who they believe is the StoryKiller.
        </li>
        <li>
          If the StoryKiller is voted out, the survivors win. If not, the game continues.
        </li>
      </ol>
      <h2>Winning the Game</h2>
      <p>
        The game ends when the StoryKiller is voted out (survivors win) or when the number of survivors is reduced to two (StoryKiller wins).
      </p>
      <h2>Tips</h2>
      <ul>
        <li>
          Be attentive to other players' stories. Look for inconsistencies.
        </li>
        <li>
          The StoryKiller should blend in and avoid suspicion.
        </li>
      </ul>
      <button className="back-button" onClick={handleBackClick}>Back to Main Menu</button>
    </div>
  );
}

export default HowToPlay;
