// Player.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './Player.css';
import storyKillerBubble from './images/storykillerbubble.png'; // Adjusted path
import deadImage from './dead.png'; // Adjust the path if necessary
import gotTheStoryKillerImage from './images/gotthestorykiller.png'; // Adjust the path if necessary
import storyKillerWinsImage from './images/storykillerwins.png'; // Adjust the path if necessary
import { Link } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL || '';

function Player() {
  const { gameId, playerCode } = useParams();
  const [playerInfo, setPlayerInfo] = useState(null);
  const [error, setError] = useState(null);
  const [vote, setVote] = useState('');
  const [hasVoted, setHasVoted] = useState(false);
  const [roundEndInfo, setRoundEndInfo] = useState(null);
  const [results, setResults] = useState(null);
  const [gameHistory, setGameHistory] = useState([]);

  useEffect(() => {
    const fetchPlayerInfo = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/games/${gameId}/player/${playerCode}`);
        const data = response.data;
        console.log('Player data:', data);
        
        if (!data || typeof data !== 'object') {
          throw new Error('Invalid data received from server');
        }
        
        setPlayerInfo(data);
        setGameHistory(data.gameHistory || []);

        // Update hasVoted status
        if (data.playerList && Array.isArray(data.playerList)) {
          const currentPlayer = data.playerList.find(p => p.code === playerCode);
          setHasVoted(currentPlayer ? currentPlayer.hasVoted : false);
        } else {
          console.error('Invalid playerList data:', data.playerList);
        }

        if (data.roundEnded && !roundEndInfo) {
          setRoundEndInfo({
            endingPlayerName: data.endingPlayerName,
            killedPlayerName: data.killedPlayerName,
          });
        }

        if (data.votingComplete && !results) {
          fetchResults();
        }

        // Fetch results if the game is over
        if (data.gameOver && !results) {
          fetchResults();
        }
      } catch (error) {
        console.error('Error fetching player info:', error);
        setError('Failed to fetch player information. Please try again.');
      }
    };

    const fetchResults = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/games/${gameId}/results`);
        setResults(response.data);
        setGameHistory(response.data.gameHistory);
      } catch (error) {
        console.error('Error fetching results:', error);
        setError('Failed to fetch voting results. Please try again.');
      }
    };

    fetchPlayerInfo();
    const interval = setInterval(fetchPlayerInfo, 5000);
    return () => clearInterval(interval);
  }, [gameId, playerCode, roundEndInfo, results]);

  const handleEndRound = async () => {
    const confirmEnd = window.confirm('Has everyone told their story?');
    if (!confirmEnd) return;

    // Proceed to end the round
    try {
      const response = await axios.post(`${API_URL}/api/games/${gameId}/end-round`, { playerCode });
      setRoundEndInfo({
        endingPlayerName: response.data.endingPlayerName,
        killedPlayerName: response.data.killedPlayerName,
      });
    } catch (error) {
      console.error('Error ending round:', error);
      setError('Failed to end the round. Please try again.');
    }
  };

  const handleVote = async () => {
    if (!vote) {
      setError('Please select a player to vote for.');
      return;
    }
    if (hasVoted) {
      setError('You have already voted.');
      return;
    }
    try {
      await axios.post(`${API_URL}/api/games/${gameId}/vote`, {
        playerCode,
        vote,
      });
      setHasVoted(true);
      setVote('');
    } catch (error) {
      console.error('Error submitting vote:', error);
      setError(error.response?.data?.error || 'Failed to submit vote. Please try again.');
    }
  };

  const handleNextRound = async () => {
    try {
      await axios.post(`${API_URL}/api/games/${gameId}/next-round`, { playerCode });
      setRoundEndInfo(null);
      setResults(null);
    } catch (error) {
      console.error('Error starting next round:', error);
      setError('Failed to start the next round. Please try again.');
    }
  };

  const renderGameHistory = () => {
    const historyToDisplay = results && results.gameOver
      ? gameHistory.slice(0, -1) // Exclude the last round
      : gameHistory;

    return (
      <div className="game-history">
        <h3>Game History</h3>
        {historyToDisplay.map((round, index) => (
          <div key={index} className="history-round">
            <h4>Story Round {round.round}</h4>
            <p>{round.storiesCount} Stories Told, 1 Killing</p>
            <p>
              {round.gameOver
                ? round.storyKillerCaught
                  ? 'StoryKiller voted out. Survivors win!'
                  : 'StoryKiller wins!'
                : 'StoryKiller survived the vote'}
            </p>
          </div>
        ))}
      </div>
    );
  };

  if (error) return <div className="error-message">Error: {error}</div>;
  if (!playerInfo) return <div className="loading">Loading...</div>;

  const isPlayerKilled = playerInfo.killedPlayerName === playerInfo.name;

  return (
    <div className="player-container">
      <img
        src={storyKillerBubble}
        alt="StoryKiller"
        className="player-header-image"
      />
      {!results && (
        <>
          <div className="player-card">
            <h2>Welcome, {playerInfo.name}!</h2>
            <h2>Your challenge is to tell a short story including your story phrase.</h2>
            <div className="your-story-phrase">
              <p>Your story phrase:</p>
              <p className="story-phrase">{playerInfo.storyPhrase}</p>
            </div>
            {isPlayerKilled && (
              <div className="killed-message">
                <p>You have been killed! You can no longer participate in the game.</p>
                <img src={deadImage} alt="Dead" className="dead-image" />
              </div>
            )}
            {!isPlayerKilled && (
             <div className="storykiller-info">
             {playerInfo.isStoryKiller ? (
               <p className="storykiller-text is-storykiller">
                 Pssst! You are the <em>StoryKiller</em>! Keep it a secret!
               </p>
             ) : (
               <p className="storykiller-text not-storykiller">
                 You are <strong>not</strong> the StoryKiller.
               </p>
             )}
           </div>
            )}
            {!playerInfo.roundEnded && !isPlayerKilled && (
              <button className="end-round-button" onClick={handleEndRound}>End Round</button>
            )}
            {roundEndInfo && (
              <div className="round-end-info">
                <p>{roundEndInfo.endingPlayerName} has ended the round.</p>
                <p>Latest news: {roundEndInfo.killedPlayerName} has been killed!</p>
                {!isPlayerKilled && <p>Who do you think the StoryKiller is?</p>}
              </div>
            )}
            {playerInfo.votingStarted && !playerInfo.votingComplete && !isPlayerKilled && !playerInfo.gameOver && (
              <div className="voting-section">
                <h3>Voting Time!</h3>
                {!hasVoted ? (
                  <>
                    {playerInfo.playerList.filter(p => !p.isKilled && p.code !== playerCode).length > 0 ? (
                      <>
                        <select value={vote} onChange={(e) => setVote(e.target.value)} disabled={hasVoted}>
                          <option value="">Select a player</option>
                          {playerInfo.playerList.filter(p => !p.isKilled && p.code !== playerCode).map((p) => (
                            <option key={p.code} value={p.code}>{p.name}</option>
                          ))}
                        </select>
                        <button className="submit-vote-button" onClick={handleVote} disabled={hasVoted}>Submit Vote</button>
                      </>
                    ) : (
                      <p>No players available to vote for.</p>
                    )}
                  </>
                ) : (
                  <p>You have voted.</p>
                )}
                <h4>Voting Status:</h4>
                <ul>
                  {playerInfo.playerList.filter(p => !p.isKilled).map((p) => (
                    <li key={p.code}>
                      {p.name} ({p.hasVoted ? 'voted' : 'not yet voted'})
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {playerInfo.votingComplete && !results && (
              <p className="fetching-results">Voting is complete. Fetching results...</p>
            )}
          </div>
        </>
      )}
      {results && results.gameOver && (
        <div className="results-section">
          {results.storyKillerCaught ? (
            // Survivors win
            <div>
              <p>Yes! The StoryKiller was caught! Survivors win!</p>
              <img
                src={gotTheStoryKillerImage}
                alt="Got the StoryKiller"
                className="got-storykiller-image"
              />
              <button
                className="play-again-button"
                onClick={() => window.location.href = '/'}
              >
                Click to play another game
              </button>
            </div>
          ) : (
            // StoryKiller wins
            <div>
              <p>The StoryKiller has won the game!</p>
              <img
                src={storyKillerWinsImage}
                alt="StoryKiller Wins"
                className="storykiller-wins-image"
              />
              <button
                className="play-again-button"
                onClick={() => window.location.href = '/'}
              >
                Click to play another game
              </button>
            </div>
          )}
        </div>
      )}
      {gameHistory.length > 0 && !results?.gameOver && renderGameHistory()}

    </div>
  );
}

export default Player;
