import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GameSetup from './GameSetup';
import Player from './Player';
import HowToPlay from './HowToPlay';
// import GameSettings from './GameSettings';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<GameSetup />} />
          <Route path="/game/:gameId/player/:playerCode" element={<Player />} />
          <Route path="/how-to-play" element={<HowToPlay />} />
{/* //   <Route path="/game-settings" element={<GameSettings />} /> */}
          {/* <Route path="/settings" element={<GameSettings />} /> */}

        </Routes>
      </div>
    </Router>
  );
}

export default App;
